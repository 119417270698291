/* eslint-disable @typescript-eslint/no-unsafe-assignment */
'use client'

import useHydrateFormWithUserData from './useHydrateFormWithUserData'
import useI18nZodErrorMap from '@/i18n/zod/useI18nZodErrorMap'
import useLocale from '@/i18n/useLocale'
import { AppError, type IAppError, toErrorObject } from '@/helpers/errors'
import { type BaseSyntheticEvent, type FormEvent, useCallback, useMemo, useState, useTransition } from 'react'
import { type Path, useForm } from 'react-hook-form'
import { createSyntheticEvent } from '@/helpers/utils'
import { errorToFieldErrors, fromFormData, toFormData } from '@/form/utils'
import { useFormState } from 'react-dom'
import { useTranslations } from 'next-intl'
import { zodResolver } from '@hookform/resolvers/zod'
import type { BaseFormOperation, BaseFormState } from '@/form/types'
import type { ImperativeSubmit, SetterTools, UseActionFormProps, UseActionFormReturn } from './types'
import type { z } from 'zod'

/**
 * This hook combines a form action (that you can create by calling toFormAction on a normal server action)
 * to provide a server-side validation, useFormState from React, for cross-call server-side state persistance
 * and React Hook Form for client-side validation and client, it's return is mostly compatible with useForm from RHF
 * @param options useActionForm configuration. See UseActionFormProps for more details
 * @returns
 */
function useActionForm<
  TSchema extends z.Schema,
  TOperation extends BaseFormOperation,
  TState extends BaseFormState<TOperation>,
>({
  action,
  actionContext,
  schema,
  defaultValues: defaultValues_,
  getErrorMap,
  beforeFormSubmit,
  afterClientSubmitError,
  afterClientSubmitSuccess,
  afterSubmitSuccess,
  afterSubmitError,
  defaultErrorMessage,
  id: externalId,
  ...props
}: UseActionFormProps<TSchema, TOperation, TState>): UseActionFormReturn<z.infer<TSchema>, TState['data']> {
  const locale = useLocale()

  const t = useTranslations('nextjs')
  useI18nZodErrorMap()
  const [serverState, clientAction] = useFormState(action, {
    ...actionContext,
    locale,
    fields: toFormData(defaultValues_),
  } as Awaited<TState>)

  const defaultValues = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => ({ ...defaultValues_, ...fromFormData<z.infer<TSchema>>(serverState.fields) }),
    [serverState.fields, defaultValues_]
  )

  const [submitActionInProgress, startSubmitAction] = useTransition()
  const [busy, setBusy] = useState(false)
  const resolver = useMemo(
    () => zodResolver(schema, { errorMap: getErrorMap?.(t, locale) }),
    [schema, getErrorMap, t, locale]
  )

  const {
    handleSubmit,
    formState: clientFormState,
    getValues,
    setError,
    setFocus,
    setValue,
    clearErrors,
    ...rest
  } = useForm({
    resolver,
    defaultValues,
    ...props,
  })

  const { id } = useHydrateFormWithUserData(setValue, defaultValues_, externalId)

  const tools: SetterTools<z.infer<TSchema>> = useMemo(
    () => ({ setBusy, setError, setFocus, setValue, clearErrors }),
    [clearErrors, setError, setFocus, setValue]
  )

  const handleServerError = useCallback(
    async (error: IAppError) => {
      for (const err of error.errors) {
        if (err.type === 'FieldError') {
          setError(err.path.join('.') as Path<z.infer<TSchema>>, { message: err.message, type: 'server' })
          continue
        }
        let message = defaultErrorMessage
        if ('message' in err && err.message) {
          message = err.message
        }
        setError('root', { message })
      }
      if (typeof afterSubmitError === 'function') {
        const input = getValues()
        const errors = toErrorObject<z.infer<TSchema>>(error.errors)
        await afterSubmitError(errors, input, tools, { shouldSetErrors: true })
      }
    },
    [afterSubmitError, defaultErrorMessage, setError, getValues, tools]
  )

  const onSubmitInner = useCallback(
    async (input: z.infer<TSchema>, event?: BaseSyntheticEvent) => {
      if (!event) {
        throw new TypeError('Event is missing')
      }
      await afterClientSubmitSuccess?.(input, tools)
      const formData = toFormData(input)
      let result = await action(serverState, formData)
      // in case we have an uncaught error
      if (!result) {
        result = {
          error: new AppError([
            {
              message: t('errors.messages.try_again'),
              type: 'GeneralError',
            },
          ]),
          data: undefined,
          locale,
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data, error } = result
      if (data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        await afterSubmitSuccess?.(result.data, input, tools)
      } else if (error) {
        await handleServerError(result.error)
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { data, error }
    },
    [afterClientSubmitSuccess, tools, action, serverState, t, locale, afterSubmitSuccess, handleServerError]
  )

  const serverErrors = useMemo(() => {
    return errorToFieldErrors(serverState.error, defaultErrorMessage)
  }, [serverState.error, defaultErrorMessage])

  const serverStateIsValid = useMemo(() => {
    return !serverState.error?.errors.length
  }, [serverState.error])

  const formState = useMemo(() => {
    const {
      errors,
      isValid,
      isDirty,
      isLoading,
      isSubmitSuccessful,
      isSubmitted,
      isSubmitting,
      isValidating,
      submitCount,
      dirtyFields,
      touchedFields,
      disabled,
      validatingFields,
    } = clientFormState // clientFormState is a Proxy, so we cannot simply spread values here

    return {
      submitCount,
      dirtyFields,
      touchedFields,
      isDirty,
      isLoading,
      isSubmitSuccessful,
      isSubmitted,
      isSubmitting,
      isValidating,
      isValid: isValid && serverStateIsValid,
      disabled,
      validatingFields,
      errors: { ...errors, ...serverErrors },
    }
  }, [clientFormState, serverStateIsValid, serverErrors])

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      startSubmitAction(async () => {
        const values = getValues()
        await beforeFormSubmit?.(values, tools)
        await handleSubmit(onSubmitInner, async (errors) => {
          await afterClientSubmitError?.(errors, values, tools)
        })(event)
      })
    },
    [beforeFormSubmit, getValues, tools, handleSubmit, onSubmitInner, afterClientSubmitError]
  )

  const submit: ImperativeSubmit<z.infer<TSchema>, TState['data']> = useCallback(() => {
    return new Promise((resolve, reject) => {
      startSubmitAction(async () => {
        try {
          await beforeFormSubmit?.(getValues(), tools)
          await handleSubmit(
            async (input) => {
              const formEvent = createSyntheticEvent(
                new FormDataEvent('submit', { cancelable: true, formData: new FormData() })
              )
              const res = await onSubmitInner(input, formEvent)
              if (res.data) {
                return resolve({ data: res.data, input })
              }
              // if we have only field errors these are simply validation errors
              if (!res.error?.errors.find((error) => error.type === 'FieldError')) {
                return reject(new Error(ActionFormSubmitErrorTypes.ValidationError))
              }
              reject(
                new Error(`${ActionFormSubmitErrorTypes.ServerError} - errors: ${JSON.stringify(res.error?.errors)}`)
              )
            },
            async (errors) => {
              const values = getValues()
              await afterClientSubmitError?.(errors, values, tools)
              reject(new Error(ActionFormSubmitErrorTypes.ValidationError))
            }
          )()
        } catch (error) {
          reject(error)
        }
      })
    })
  }, [beforeFormSubmit, getValues, tools, handleSubmit, onSubmitInner, afterClientSubmitError])

  return {
    ...rest,
    setBusy,
    setFocus,
    setValue,
    setError,
    clearErrors,
    getValues,
    submit,
    formState,
    handleSubmit,
    onSubmit,
    defaultValues,
    busy: busy || submitActionInProgress,
    action: clientAction,
    id,
  }
}

export const ActionFormSubmitErrorTypes = {
  ValidationError: 'ACTION_FORM_VALIDATION_ERROR',
  ServerError: 'ACTION_FORM_SERVER_ERROR',
} as const

export default useActionForm
